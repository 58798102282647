import { Button, Flex, Popover } from 'antd'
import React, { useEffect, useState } from 'react'
import StatsItem from './StatsItem'
import { CreateVersionModal } from '../shared/modals'
import CreateProcessModal from '../shared/modals/processes/CreateProcessModal'
import { useStore } from '../../stores'
import { observer } from 'mobx-react'

import moment from 'moment'

export const rearrangeName = (string) => {
  // convert name string into array, split by spaces
  string = string.split(' ')

  // get first name
  const firstPart = string[0]

  // reconnect the rest of the name by spaces
  // trim is just to remove trailing spaces
  const restOfString = string.slice(1).join(' ').trim()

  // return rearranged name, separated by comma
  return `${restOfString} ${firstPart}`
}

const currentVersionName = 'Firmware 1.2'
const currentFirmwareVersion = '1.2'
const currentDatabaseVersion = '23.06.03'
const OTAScreenHeader = observer(() => {
	const { dataStore, versionStore} = useStore()


	useEffect(() => {
		versionStore.getLastVersion();
	}, [])

	const [isCreateVersionModalOpen, setIsCreateVersionModalOpen] = useState()
	const handleOpenCreateVersionModalOpen = () => setIsCreateVersionModalOpen(true)
	const handleCloseCreateVersionModalOpen = () => setIsCreateVersionModalOpen(false)

	const [isProcessModalOpen, setIsProcessModalOpen] = useState()
	const handleOpenProcessModalOpen = () => setIsProcessModalOpen(true)

	const handleCloseProcessModalOpen = async (error) => {
		setIsProcessModalOpen(false)
		await dataStore.getProcesses()
	}

	const customerPopover = (children) => (
		<Popover style={{ width: 'fit-content' }} content={versionStore.versionData?.hash || `N/A`} title='Version Hash'>
			{children}
		</Popover>
	)
	

	return (
		<Flex
			gap='middle'
			justify='space-between'
			align='flex-start'
			wrap='wrap'
			style={{ width: '100%', marginBottom: '24px' }}
		>
			<Flex
				justify='flex-start'
				align='flex-start'
				style={{
					maxWidth: '59%',
					flexWrap: 'wrap',
					columnGap: '1.5rem',
					rowGap: '1rem',
				}}
			>
				{versionStore.versionData && (
					<>
						<StatsItem title='Firmware Version' value={versionStore.versionData?.firmwareVersion || `N/A`} popover={customerPopover} />
						<StatsItem title='Created by' value={rearrangeName(versionStore.versionData?.createdBy || '')} />
						<StatsItem title='Database Version' value={versionStore.versionData?.databaseVersion || `N/A`} />
						<StatsItem title='Creation Date' value={moment(versionStore.versionData?.createdAt).format('MM.DD.YYYY')} />
					</>
				)}
			</Flex>

			<Flex gap='middle' justify='flex-end' align='flex-end' style={{ maxWidth: '39%', flexWrap: 'wrap' }}>
				<Button onClick={handleOpenCreateVersionModalOpen} type='primary'>
					Create New Version
				</Button>
				<Button onClick={handleOpenProcessModalOpen} type='primary'>
					Create New Process
				</Button>
			</Flex>

			{isCreateVersionModalOpen && (
				<CreateVersionModal isOpen={isCreateVersionModalOpen} onCancel={handleCloseCreateVersionModalOpen} />
			)}

			{isProcessModalOpen && (
				<CreateProcessModal
					versions={{
						versionName: currentVersionName,
						firmwareVersion: currentFirmwareVersion,
						databaseVersion: currentDatabaseVersion,
					}}
					isOpen={isProcessModalOpen}
					onCancel={handleCloseProcessModalOpen}
				/>
			)}
		</Flex>
	)
})

export default OTAScreenHeader
