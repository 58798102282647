import React from "react"
import PropTypes from "prop-types"
import { Form, Input, Select } from "antd"

const BaseUserEditForm = ({ emailDisabled = false }) => {
	return (
		<>
			<Form.Item label="Email" name="email">
				<Input disabled={emailDisabled} />
			</Form.Item>
			<Form.Item label="First Name" name="firstName">
				<Input />
			</Form.Item>
			<Form.Item label="Last Name" name="lastName">
				<Input />
			</Form.Item>
		</>
	)
}

BaseUserEditForm.propTypes = {
	emailDisabled: PropTypes.bool,
}

export default BaseUserEditForm
