import { Card, Col, DatePicker, Tabs, Layout, Space, Button, Row, Table, Typography, Tag, Breadcrumb } from "antd"
import PropTypes from "prop-types"
import moment from "moment"
import { presets } from "../utils/dateRangePreset"
import { getDivideResult } from "../utils/calculations"
import dayjs from "dayjs"
const _ = require("lodash")
const utc = require("dayjs/plugin/utc")
dayjs.extend(utc)
import React, { useEffect, useState } from "react"
import { API } from "../App"
import { ScreenLayout, Top5Widget, AvgFlavorChart, FlavorOppsChart } from "../components"
import { DownloadOutlined } from "@ant-design/icons"
import { CSVLink } from "react-csv"
import { useNavigate } from "react-router"
import { useLocation, Link } from "react-router-dom"
import { useStore } from "../stores"

const { Header } = Layout
const { Text } = Typography
const { Column } = Table
const { RangePicker } = DatePicker

const getEarliestCondensedLogDate = (condensedLog) => {
	if (!condensedLog || _.isEmpty(condensedLog)) return null
	let earliestDate = null

	for (const dateStr in condensedLog) {
		const currentDate = new Date(dateStr)

		if (currentDate.getTime() < new Date("2015-01-01").getTime()) {
			continue
		}

		if (!earliestDate || currentDate < earliestDate) {
			earliestDate = currentDate
		}
	}

	return earliestDate
}

const isSecondDateBeforeFirst = (date1Str, date2Str) => {
	const date1 = moment(new Date(date1Str)).startOf("day")
	const date2 = moment(new Date(date2Str)).startOf("day")

	// Compare using Moment.js objects
	if (date2.isBefore(date1)) {
		return true
	}

	return false
}

const useGetAllParams = () => {
	const { search } = useLocation()
	const query = new URLSearchParams(search)
	const params = {}
	for (let param of query.entries()) {
		params[param[0]] = param[1]
	}
	return params
}

const getFormattedDate = (dateString) => {
	if (!dateString || typeof dateString !== "string") return ""
	return dateString.replace(/ /g, "+")
}

const StoreDetails = () => {
	const { search, pathname } = useLocation()
	const { authStore } = useStore()
	const params = useGetAllParams()
	const navigate = useNavigate()

	const { serial_number, location_id, portal_number, customer_number, dispenserID, storeID } = params

	let { dateRangeStart, dateRangeEnd } = params

	let initialStartDateRange = getFormattedDate(dateRangeStart)
	let initialEndDateRange = getFormattedDate(dateRangeEnd)

	if (!dateRangeStart) dateRangeStart = dayjs().subtract(1, "week")
	if (!dateRangeEnd) dateRangeEnd = dayjs()

	const initialDateRange = [dayjs(initialStartDateRange), dayjs(initialEndDateRange)]

	const [store, setStore] = useState(null)
	const [storeAddress, setStoreAddress] = useState(null)
	const [storeHierarchy, setStoreHierarchy] = useState("")
	const [dispenser, setDispenser] = useState(null)
	const [filteredDateRange, setFilteredDateRange] = useState(initialDateRange)

	const [breadcrumbs, setBreadcrumbs] = useState([])

	const [actualReportedDays, setActualReportedDays] = useState(0)
	const [opportunities, setOpportunities] = useState(0)
	const [flavorings, setFlavorings] = useState(0)
	const [performance, setPerformance] = useState(0)
	const [topDrugs, setTopDrugs] = useState([])
	const [topDrugPercent, setTopDrugPercent] = useState(0)
	const [topFlavors, setTopFlavors] = useState([])
	const [topFlavorPercent, setTopFlavorPercent] = useState(0)
	const [performanceData, setPerformanceData] = useState([])
	const [chartData, setChartData] = useState([])

	const [allLogs, setAllLogs] = useState([])
	const [logs, setLogs] = useState([])
	const [csvData, setCSVData] = useState([])
	const [loading, setLoading] = useState(false)
	const [headers, setHeaders] = useState([
		{ label: "Date", key: "date" },
		{ label: "Time", key: "time" },
		{ label: "Action Type", key: "action_type" },
		{ label: "Drug", key: "drug" },
		{ label: "NDC", key: "ndc" },
		{ label: "Water Volume", key: "water_volume" },
		{ label: "Flavor Selected", key: "selected_flavor" },
		{ label: "User Initials", key: "operator" },
		{ label: "Repeat", key: "repeat" },
	])

	const isAdmin = authStore.isAdmin

	useEffect(() => {
		let storeid = storeID
		if (dispenserID) storeid = dispenserID

		let filter = {}
		if (serial_number) {
			filter.where = { dispenser_serial_number: serial_number }
			API.getDispenser(filter).then((res) => {
				if (res.data.length > 0) {
					setDispenser(res.data[0])
					filter.where = { id: res.data[0].storeId }
					API.getStore(filter).then((res) => {
						if (res.data.length > 0) setStore(res.data[0])
					})
				}
			})
		} else {
			if (location_id) filter.where = { location_id: location_id }
			if (portal_number) filter.where = { portal_number: portal_number }
			if (customer_number) filter.where = { customer_number: customer_number }
			if (storeid) filter.where = { id: storeid }
			filter.include = [{ relation: "dispensers" }]
			API.getStore(filter).then((res) => {
				if (res.data.length > 0) {
					setStore(res.data[0])
					if (res.data[0].dispensers?.length > 0) setDispenser(res.data[0].dispensers[0])
				}
			})
		}
	}, [])

	useEffect(() => {
		if (store) {
			let address = store.address1
			if (store.address2) address += `, ${store.address2}`
			address += `, ${store.city}, ${store.state} ${store.zip}`
			setStoreAddress(address)

			let breadcrumbItems = [{ text: "Home", link: "/", level: "0" }]
			let queryStringResult = `customerName=${store.hierarchyLevel1}`
			let storeTitle = store.hierarchyLevel1

			const hierarchyLevelArray = [
				store.hierarchyLevel1,
				store.hierarchyLevel2,
				store.hierarchyLevel3,
				store.hierarchyLevel4,
				store.hierarchyLevel5,
			]

			for (const level of hierarchyLevelArray) {
				const elIndex = hierarchyLevelArray.indexOf(level)
				if (elIndex === -1 || !level) {
					continue
				}

				if (elIndex !== 0) {
					queryStringResult += `&hierarchyLevel${elIndex + 1}=${level}`
					storeTitle += ` > ${level}`
				}

				breadcrumbItems.push({
					level: elIndex + 1,
					text: level, // Update the breadcrumb item text with the selected hierarchy level.
					link: `/dashboard?${queryStringResult}&dateRangeStart=${filteredDateRange[0].format()}&dateRangeEnd=${filteredDateRange[1].format()}`, // Create a link for hierarchy level 1.
				})
			}

			if (store.store_name) {
				breadcrumbItems.push({
					level: breadcrumbItems.length + 1,
					text: store.store_name, // Update the breadcrumb item text with the selected hierarchy level.
					link: search, // Create a link for hierarchy level 1.
				})
			}

			storeTitle += " >"
			setStoreHierarchy(storeTitle)
			setBreadcrumbs(breadcrumbItems)
		}
	}, [store, filteredDateRange])

	const dispenseTypes = [
		"recons_dispense",
		"recons_and_flavor_dispense",
		"flavor_dispense",
		"manual_water_dispense",
		"manual_flavor_dispense",
	]

	const wifiTypes = ["wifi_connection", "wifi_tx_success", "wifi_tx_failed"] //, wifi_tx_success, wifi_tx_failed
	const powerTypes = ["power_on"]

	const flavorNames = [
		"Watermelon",
		"Sweetening",
		"Raspberry",
		"Bubblegum",
		"Bitterness Suppressor",
		"Mango",
		"Lemon",
		"Apple",
		"Banana",
		"Grape",
		"Cherry",
		"Strawberry",
	]
	const actionTypeLabels = {
		recons_dispense: "Recon Only",
		recons_and_flavor_dispense: "Recon + Flavor",
		flavor_dispense: "Flavor Only",
		manual_water_dispense: "Manual Water",
		manual_flavor_dispense: "Manual Flavor",
	}

	const getFlavorObject = (data, flavorName) => {
		let obj = data.flavors?.find((d) => d.flavor_name === flavorName)
		let obj_volume = obj ? obj.flavor_volume : ""
		let obj_expiration = obj ? `${obj.expiration_month}/${obj.expiration_year}` : ""
		let obj_lot_num = obj ? obj.lot_number : ""
		return { obj_volume, obj_lot_num, obj_expiration }
	}

	useEffect(() => {
		if (dispenser) {
			let tempHeaders = []
			flavorNames.forEach((flavor) => {
				let flavorKey = flavor.toLowerCase().replace(" ", "_")
				//setHeaders([...headers, { label: `${flavor} Vol`, key: `${flavorKey}_volume` }])
				tempHeaders.push({ label: `${flavor} Vol`, key: `${flavorKey}_volume` })
			})
			if (dispenser?.dispenser_type !== "Pro") {
				flavorNames.forEach((flavor) => {
					let flavorKey = flavor.toLowerCase().replace(" ", "_")
					tempHeaders.push({
						label: `${flavor} Lot #`,
						key: `${flavorKey}_lot_num`,
					})
				})

				flavorNames.forEach((flavor) => {
					let flavorKey = flavor.toLowerCase().replace(" ", "_")
					tempHeaders.push({
						label: `${flavor} Exp`,
						key: `${flavorKey}_expiration`,
					})
				})
			}
			setHeaders((orig) => [...orig, ...tempHeaders])
		}
	}, [dispenser])
	useEffect(() => {
		if (!filteredDateRange || !store) return

		setLoading(true)
		API.getLogs({
			order: "created DESC",
			where: {
				and: [
					{ storeId: { eq: store.id } },
					{ created: { gte: filteredDateRange[0].startOf("d").toDate() } },
					{ created: { lte: filteredDateRange[1].endOf("d").toDate() } },
				],
			},
		}).then((res) => {
			setLoading(false)
			let dispenseLogs = res.data.filter((d) => dispenseTypes.includes(d.message_type))
			setLogs(dispenseLogs)
			setAllLogs(res.data)
			let data = dispenseLogs.map((d) => {
				let date = dayjs(d.created).utc().format("MM/DD/YYYY")
				let time = dayjs(d.created).utc().format("hh:mm A")
				let drug = d.drug_name
				let action_type = actionTypeLabels[d.message_type] ? actionTypeLabels[d.message_type] : d.message_type
				let selected_flavor = d.selected_flavor
				let operator = d.operator
				let water_volume = d.water_volume
				let ndc = d.ndc ? '=""' + d.ndc + '""' : ""
				let repeat = d.repeat ? "Repeat" : ""
				let returnData = {
					date,
					time,
					action_type,
					drug,
					ndc,
					water_volume,
					selected_flavor,
					operator,
					repeat,
				}

				flavorNames.forEach((flavor) => {
					let flavorObj = getFlavorObject(d, flavor)
					let flavorKey = flavor.toLowerCase().replace(" ", "_")
					returnData[`${flavorKey}_volume`] = flavorObj.obj_volume
					returnData[`${flavorKey}_lot_num`] = flavorObj.obj_lot_num
					returnData[`${flavorKey}_expiration`] = flavorObj.obj_expiration
				})

				return returnData
			})
			setCSVData(data)
		})
	}, [filteredDateRange, store])

	useEffect(() => {
		if (!filteredDateRange || !store || !store?.condensed_log) return

		let actualDays = 0
		let opportunityCountTotal = 0
		let flavoringCountTotal = 0
		let allDrugs = {}
		let allFlavors = {}
		let data = []

		let dateRange = [dayjs(filteredDateRange[0]), dayjs(filteredDateRange[1])]

		while (dateRange[0].isBefore(dateRange[1]) || dateRange[0].isSame(dateRange[1])) {
			const dayKey = dateRange[0].format("MM/DD/YYYY")

			let dayObj = store["condensed_log"]["day"][dayKey]
			let opportunityCount = dayObj ? dayObj.opportunities : 0
			let flavoringCount = dayObj ? dayObj.flavorings : 0

			opportunityCountTotal += opportunityCount
			flavoringCountTotal += flavoringCount

			if (dayObj && dayObj.opportunities) {
				actualDays += 1

				for (const [key, value] of Object.entries(dayObj.drugs)) {
					if (allDrugs[key]) allDrugs[key] += value
					else allDrugs[key] = value
				}
				for (const [key, value] of Object.entries(dayObj.flavors)) {
					if (allFlavors[key]) allFlavors[key] += value
					else allFlavors[key] = value
				}
			}

			let performance = (getDivideResult(flavoringCount, opportunityCount) * 100).toFixed(2)

			if (isNaN(performance)) {
				performance = (0).toFixed(2)
			}
			let dataRow = {
				key: dayKey,
				date: dayKey,
				opportunities: opportunityCount,
				flavorings: flavoringCount,
				performance: +performance,
			}
			data.push(dataRow)

			dateRange = [dateRange[0].add(1, "d"), dateRange[1]]
		}

		let drugs = Object.entries(allDrugs)
			.map(([key, value]) => ({ label: key, value }))
			.sort((a, b) => b.value - a.value)
			.slice(0, 5)
		let flavors = Object.entries(allFlavors)
			.map(([key, value]) => ({ label: key, value }))
			.sort((a, b) => b.value - a.value)
			.slice(0, 5)

		setTopDrugs(drugs)
		setTopFlavors(flavors)

		if (drugs.length > 0) {
			setTopDrugPercent(parseInt(getDivideResult(drugs[0].value, opportunityCountTotal) * 100))
		}
		if (flavors.length > 0) {
			setTopFlavorPercent(parseInt(getDivideResult(flavors[0].value, flavoringCountTotal) * 100))
		}

		let performanceTotal =
			opportunityCountTotal > 0 || flavoringCountTotal > 0
				? (getDivideResult(flavoringCountTotal, opportunityCountTotal) * 100).toFixed(2)
				: 0

		setActualReportedDays(actualDays)
		setOpportunities(opportunityCountTotal)
		setFlavorings(flavoringCountTotal)
		setPerformance(performanceTotal)
		setChartData(data)
	}, [filteredDateRange, store])

	useEffect(() => {
		if (filteredDateRange?.length > 1) {
			let opportunitiesPerDay = 0
			let flavoringsPerDay = 0
			flavoringsPerDay = getDivideResult(flavorings, actualReportedDays).toFixed(1)
			opportunitiesPerDay = getDivideResult(opportunities, actualReportedDays).toFixed(1)
			let data = [
				{ label: "Opportunities", value: opportunities },
				{ label: "Opps/Day", value: opportunitiesPerDay },
				{ label: "Flavorings", value: flavorings },
				{ label: "Flavorings/Day", value: flavoringsPerDay },
			]
			setPerformanceData(data)
		}
	}, [flavorings, opportunities])

	const flavorTable = (log) => (
		<div style={{ padding: 15, width: 700, float: "right" }}>
			{log.message_type === "recons_and_flavor_dispense" && (
				<>
					<Text style={{ paddingLeft: 8 }} strong>
						Water Vol.
					</Text>
					<Text>{` ${log.water_volume}ml`}</Text>
					<br />

					<Text style={{ paddingLeft: 8 }} strong>
						Flavor Selected:{" "}
					</Text>
					<Text>{log.selected_flavor}</Text>
				</>
			)}
			{log.message_type === "flavor_dispense" && (
				<>
					<Text style={{ paddingLeft: 8 }} strong>
						Flavor Selected:{" "}
					</Text>
					<Text>{log.selected_flavor}</Text>
				</>
			)}
			{log.operator && (
				<>
					<Text style={{ paddingLeft: 8 }} strong>
						User Initials:{" "}
					</Text>
					<Text>{log.operator}</Text>
				</>
			)}
			<Table style={{ marginTop: 5 }} rowClassName="nestedRow" dataSource={log.flavors} pagination={false} size="small">
				<Column title="Ingredients" dataIndex="flavor_name" key="flavor_name" />
				<Column title="Volume" dataIndex="flavor_volume" key="flavor_volume" />
				{dispenser?.dispenser_type !== "Pro" && (
					<>
						<Column title="Lot No." dataIndex="lot_number" key="lot_number" />
						<Column
							title="Exp. Date"
							dataIndex="exp"
							key="exp"
							render={(_text, record) => `${record.expiration_month}/${record.expiration_year}`}
						/>
					</>
				)}
			</Table>
		</div>
	)

	const DetailPanel = (props) => {
		let csvData = allLogs
			.filter((d) => props.types.includes(d.message_type))
			.map((d) => {
				let data = {
					...d,
					created_date: dayjs(d.created).utc().format("MM/DD/YYYY"),
					created_time: dayjs(d.created).utc().format("hh:mm A"),
				}

				if (props.title === "Water Quality") {
					// data.temp = d.waterquality_temp_avg
					// data.temp = `${d.temp_C}/${(parseFloat(d.temp_C) * 1.8 + 32).toFixed(2)}`
				}

				if (props.title === "WiFi Transmission Log")
					data.status = data.message_type === "wifi_tx_success" ? "success" : "failed"

				return data
			})

		let csvHeaders = []

		if (["WiFi Transmission Log", "Dispenser Sanitization"].includes(props.title)) {
			csvHeaders = [
				{ label: "Date", key: "created_date" },
				{ label: "Time", key: "created_time" },
				{ label: "Status", key: "status" },
			]
		} else if (props.title === "Power Cycles") {
			csvHeaders = [
				{ label: "Date", key: "created_date" },
				{ label: "Time", key: "created_time" },
			]
		} else if (["Water Calibration", "Sanitization Water Flush"].includes(props.title)) {
			csvHeaders = [
				{ label: "Date", key: "created_date" },
				{ label: "Time", key: "created_time" },
				{ label: "Volume", key: "volume" },
			]
		} else if (props.title === "Water Quality") {
			csvHeaders = [
				{ label: "Date", key: "created_date" },
				{ label: "Time", key: "created_time" },
				{ label: "ADC-Avg", key: "waterquality_adc_avg" },
				{ label: "uS", key: "us" },
				{ label: "Temp C", key: "waterquality_temp_avg" },
			]
		}
		return (
			<Card
				title={props.title}
				size="small"
				style={{ height: 400 }}
				extra={
					<CSVLink
						data={csvData}
						headers={csvHeaders}
						filename={`${store?.hierarchyLevel1}_${store?.store_number}_${props.title}_${filteredDateRange?.[0].format(
							"MM-DD-YY",
						)}-${filteredDateRange?.[1].format("MM-DD-YY")}.csv`}
					>
						<Button type="link">Export</Button>
					</CSVLink>
				}
			>
				<Table
					size="small"
					dataSource={allLogs.filter((d) => props.types.includes(d.message_type))}
					rowKey="id"
					loading={loading}
					pagination={{ pageSize: 20 }}
					scroll={{ y: 240 }}
				>
					<Column
						title="Date"
						dataIndex="created"
						key="created"
						render={(text) => dayjs(text).utc().format("MM/DD/YYYY - hh:mm A")}
					/>
					{props.columns.includes("status") && (
						<Column
							align="right"
							title="Status"
							dataIndex="status"
							key="status"
							render={(text, d) => {
								return props.title !== "WiFi Transmission Log"
									? text
									: d.message_type === "wifi_tx_success"
									? "success"
									: d.message_type === "wifi_tx_failed"
									? "failed"
									: text
							}}
						/>
					)}
					{props.columns.includes("volume") && <Column align="right" title="Volume" dataIndex="volume" key="volume" />}
					{props.columns.includes("waterQuality") && (
						<>
							<Column title="ADC-Avg" dataIndex="waterquality_adc_avg" key="waterquality_adc_avg" />
							<Column title="" dataIndex="us" key="us" render={() => ""} />
							<Column title="Temp C" dataIndex="waterquality_temp_avg" key="waterquality_temp_avg" />
						</>
					)}
				</Table>
			</Card>
		)
	}
	const items = [
		{
			key: "1",
			label: "Usage",
			children: (
				<>
					{filteredDateRange && (
						<>
							<Card title="Opportunities and Flavorings" size="small">
								<div style={{ float: "left" }}>
									<Text style={{ fontSize: 24 }} strong>
										{opportunities.toLocaleString()}
									</Text>
									<br />
									<div
										style={{
											backgroundColor: "#3F8DA9",
											marginTop: 7,
											marginRight: 3,
											width: 10,
											height: 10,
											borderRadius: 5,
											float: "left",
										}}
									/>
									<Text style={{ fontSize: 12 }}>Opportunities</Text>
								</div>
								<div style={{ float: "left", marginLeft: 30 }}>
									<Text style={{ fontSize: 24 }} strong>
										{flavorings.toLocaleString()}
									</Text>
									<br />

									<div
										style={{
											backgroundColor: "#F5C05B",
											marginTop: 7,
											marginRight: 3,
											width: 10,
											height: 10,
											borderRadius: 5,
											float: "left",
										}}
									/>
									<Text style={{ fontSize: 12 }}>Flavorings</Text>
								</div>
								<div style={{ clear: "both", marginBottom: 10 }}></div>
								<FlavorOppsChart data={chartData} start={filteredDateRange[0]} end={filteredDateRange[1]} />
							</Card>
							<Card title="Average Flavoring" size="small" style={{ marginTop: 20, marginBottom: 20 }}>
								<Text style={{ fontSize: 24, paddingBottom: 10 }} strong>
									{performance}%
								</Text>
								<AvgFlavorChart data={chartData} start={filteredDateRange[0]} end={filteredDateRange[1]} />
							</Card>
						</>
					)}

					<Table
						size="small"
						dataSource={logs}
						rowKey="id"
						loading={loading}
						pagination={{ pageSize: 20 }}
						expandable={{
							expandedRowRender: (record) => {
								if (record.message_type === "recons_dispense" || record.message_type === "manual_water_dispense")
									return (
										<div style={{ padding: 15, width: 700, float: "right" }}>
											<Text style={{ paddingLeft: 8 }} strong>
												Water Vol.
											</Text>
											<Text>{` ${record.water_volume}ml`}</Text>
										</div>
									)
								else return flavorTable(record)
							},
							expandRowByClick: true,
						}}
					>
						<Column
							title="Date"
							dataIndex="created"
							key="created"
							render={(text) => dayjs(text).utc().format("MM/DD/YYYY - hh:mm A")}
						/>
						<Column title="Drug" dataIndex="drug_name" key="drug_name" />
						<Column title="NDC" dataIndex="ndc" key="ndc" />
						<Column
							title="Action Type"
							dataIndex="message_type"
							key="message_type"
							render={(text) => (actionTypeLabels[text] ? actionTypeLabels[text] : text)}
						/>
						<Column
							title="Repeat"
							dataIndex="repeat"
							key="repeat"
							render={(_text, field) => (field.repeat ? <Tag>Repeat</Tag> : <></>)}
						/>
					</Table>
				</>
			),
		},
		{
			key: "2",
			label: "Details",
			children: (
				<>
					<Space>
						<DetailPanel title="WiFi Transmission Log" types={wifiTypes} columns={["status"]} />
						<DetailPanel title="Power Cycles" types={powerTypes} columns={[]} />
					</Space>
					<Space style={{ marginTop: 20, marginBottom: 20 }}>
						<DetailPanel title="Sanitization Water Flush" types={["sanitation_flush"]} columns={["volume"]} />
						<DetailPanel title="Dispenser Sanitization" types={["sanitation_prompt"]} columns={["status"]} />
					</Space>
					<Space>
						<DetailPanel title="Water Calibration" types={["water_calibration"]} columns={["volume"]} />
						<DetailPanel
							title="Water Quality"
							types={["recons_dispense", "recons_and_flavor_dispense", "manual_water_dispense"]}
							columns={["waterQuality"]}
						/>
					</Space>
				</>
			),
		},
	]

	const storeConnectedOn = store?.connectedOn

	let storeConnectedOnDate = storeConnectedOn ? dayjs(storeConnectedOn).format("MM/DD/YYYY - hh:mm A") : null

	if (!storeConnectedOnDate) {
		const earliestLogDate = getEarliestCondensedLogDate(store?.condensed_log?.day)

		storeConnectedOnDate = earliestLogDate ? dayjs(earliestLogDate).format("MM/DD/YYYY - hh:mm A") : null
	}

	return (
		<ScreenLayout showSearchBar>
			<Header
				style={{
					background: "#DCDCDC",
					height: 64,
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					paddingLeft: 20,
				}}
			>
				{store && (
					<Space direction="horizontal">
						<Breadcrumb>
							{breadcrumbs.map((item, index) => (
								<Breadcrumb.Item key={index}>
									{index === breadcrumbs.length - 1 ? ( // If it's the last breadcrumb item, just display the text without a link.
										item.text
									) : (
										<Link to={item.link}>{item.text}</Link>
									)}
								</Breadcrumb.Item>
							))}
						</Breadcrumb>
						<RangePicker
							presets={presets}
							value={filteredDateRange}
							format="MM/DD/YYYY"
							onChange={(dateRange) => {
								const searchString = "&dateRangeStart"
								const resultUrl = search.split(searchString)[0]

								navigate(
									pathname +
										resultUrl +
										`&dateRangeStart=${dateRange[0].format("MM/DD/YYYY")}&dateRangeEnd=${dateRange[1].format(
											"MM/DD/YYYY",
										)}`,
								)
								setFilteredDateRange(dateRange)
							}}
						/>
					</Space>
				)}
			</Header>
			<Layout style={{ margin: 20 }}>
				<Row gutter={[24, 24]} wrap={false} justify="end">
					<Col flex="400px">
						<Card title={store?.store_name} size="small">
							{dispenser && store && (
								<>
									<Text>Location ID</Text>
									<Text strong style={{ display: "block", marginBottom: 10 }}>
										{store.location_id}
									</Text>
									<Text>Customer Number</Text>
									<Text strong style={{ display: "block", marginBottom: 10 }}>
										{store.customer_number}
									</Text>
									<Text>Store Number</Text>
									<Text strong style={{ display: "block", marginBottom: 10 }}>
										{store.store_number}
									</Text>
									<Text>Store Address</Text>
									<Text strong style={{ display: "block", marginBottom: 10 }}>
										{storeAddress}
									</Text>
								</>
							)}
						</Card>
						<Card title="Dispenser Information" size="small" style={{ marginTop: 20 }}>
							{dispenser && store && (
								<>
									<Text>Last Transmission</Text>
									<Text strong style={{ display: "block", marginBottom: 10 }}>
										{dayjs(dispenser.last_transmission).format("MM/DD/YYYY - hh:mm A")}
									</Text>
									<Text>Dispenser ID Setup</Text>
									<Text strong style={{ display: "block", marginBottom: 10 }}>
										Yes
									</Text>
									<Text>Dispenser Type</Text>
									<Text strong style={{ display: "block", marginBottom: 10 }}>
										{dispenser.dispenser_type}
									</Text>
									<Text>Store Connected On</Text>
									<Text strong style={{ display: "block", marginBottom: 10 }}>
										{storeConnectedOnDate || "N/A"}
									</Text>
									<Text>Model Number</Text>
									<Text strong style={{ display: "block", marginBottom: 10 }}>
										{dispenser.dispenser_model_number}
									</Text>
									<Text>S/N</Text>
									<Text strong style={{ display: "block", marginBottom: 10 }}>
										{dispenser.dispenser_serial_number}
									</Text>
									<Text>Firmware</Text>
									<Text strong style={{ display: "block", marginBottom: 10 }}>
										{dispenser.dispenser_firmware}
									</Text>
									<Text>Database</Text>
									<Text strong style={{ display: "block", marginBottom: 10 }}>
										{dispenser.dispenser_database}
									</Text>
									<Text>MAC Address</Text>
									<Text strong style={{ display: "block", marginBottom: 10 }}>
										{dispenser.dispenser_mac_address}
									</Text>
									<Text>WiFi Modem Version</Text>
									<Text strong style={{ display: "block", marginBottom: 10 }}>
										{dispenser.wifi_modem_ver}
									</Text>
								</>
							)}
						</Card>

						<Top5Widget
							title="Performance Averages"
							topPercent={parseInt(performance)}
							topLabel="Flavoring %"
							data={performanceData}
							style={{ marginTop: 20 }}
							color="#F5C05B"
						/>

						<Top5Widget
							title="Top 5 Drugs"
							topPercent={topDrugPercent}
							data={topDrugs.slice(0, 5)}
							style={{ marginTop: 20 }}
							color="#679FF6"
							extraText="Of All Scripts"
						/>

						<Top5Widget
							title="Top 5 Flavors"
							topPercent={topFlavorPercent}
							data={topFlavors.slice(0, 5)}
							style={{ marginTop: 20, marginBottom: 20 }}
							color="#E5883B"
							extraText="Of All Flavors"
						/>
						{isAdmin && (
							<Space>
								<Button
									onClick={() => {
										navigate({ pathname: `/editstore/${store.id}` })
									}}
								>
									Edit Store
								</Button>
							</Space>
						)}
						{dispenser && filteredDateRange && (
							<CSVLink
								data={csvData}
								headers={headers}
								filename={`${store?.hierarchyLevel1}_${store?.store_number}_${filteredDateRange[0].format(
									"MM-DD-YY",
								)}-${filteredDateRange[1].format("MM-DD-YY")}.csv`}
							>
								<Button style={{ float: "right" }} type="primary" icon={<DownloadOutlined />}>
									Download CSV
								</Button>
							</CSVLink>
						)}
					</Col>
					<Col flex="auto">
						<Tabs defaultActiveKey="1" size="large" items={items}></Tabs>
					</Col>
				</Row>
			</Layout>
		</ScreenLayout>
	)
}

StoreDetails.propTypes = {
	location: PropTypes.object,
	types: PropTypes.array,
	title: PropTypes.string,
	columns: PropTypes.array,
}

export default StoreDetails
