import React from "react"
import PropTypes from "prop-types"
import { Dropdown, Space } from "antd"
import { DownOutlined } from "@ant-design/icons"

const UserMenu = ({ userName, items }) => {
	return (
		<Dropdown menu={{ items }} trigger={["click"]}>
			<Space>
				<span>{userName}</span>
				<DownOutlined style={{ fontSize: 15 }} />
			</Space>
		</Dropdown>
	)
}

UserMenu.propTypes = {
	userName: PropTypes.string.isRequired,
	items: PropTypes.array.isRequired,
}

export default UserMenu
