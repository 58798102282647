import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { Flex, Table } from 'antd'

import { API } from '../../../../App'
import TimeDisplay from '../../TimeDisplay'
import { Loader } from '../../../shared'
import { useOrder } from '../../../shared/hooks/UseOrder'
import { useStore } from '../../../../stores'
import { observer } from 'mobx-react'

const columns = [
	{
		title: 'Date',
		dataIndex: 'date',
		key: 'date',
		sorter: true,
		render: (_, { date }) => <TimeDisplay date={date} onlyDate />,
	},
	{
		title: 'Total Dispensers',
		dataIndex: 'totalDispensers',
		key: 'totalDispensers',
	},
	{
		title: 'Total OTA Requests',
		dataIndex: 'totalOtaRequests',
		key: 'totalOtaRequests',
	},
	{
		title: 'Total OTA Responses',
		dataIndex: 'totalOtaResponses',
		key: 'totalOtaResponses',
	},
	{
		title: (
			<span>
				OTA Response: <br /> Don’t Update
			</span>
		),
		dataIndex: 'otaDoNotUpdateResponsesCount',
		key: 'otaDoNotUpdateResponsesCount',
	},
	{
		title: (
			<span>
				OTA Response: <br /> Need Update
			</span>
		),
		dataIndex: 'otaNeedUpdateResponsesCount',
		key: 'otaNeedUpdateResponsesCount',
	},
	{
		title: 'OTA Failure',
		dataIndex: 'otaFailuresCount',
		key: 'otaFailuresCount',
	},
	{
		title: 'OTA Success',
		dataIndex: 'otaSuccessCount',
		key: 'otaSuccessCount',
	},
]
const Statistic = observer(({ selectedProcessId }) => {
	const { dataStore } = useStore()

	const [page, setPage] = useState(0)
	const [loading, setLoading] = useState()
	const [pageSize, setPageSize] = useState(10)
	const [order, handleOrder] = useOrder('date')
	const [statistic, setStatistic] = useState()

	// const customers = useMemo(
	// 	() => dataStore?.processes?.rows.find(({ id }) => id === selectedProcessId)?.customers,
	// 	[selectedProcessId, dataStore?.processes?.rows],
	// )

	// const [filter, setFilter] = useState({
	// 	where: {
	// 		// customers,
	// 		processId: selectedProcessId,
	// 	},
	// })

	const handlePagination = (page, pageSize) => {
		setPage(page - 1)
		setPageSize(pageSize)
	}

	useEffect(() => {
		setLoading(true)

		API.getOTAStatistic({
			offset: page * pageSize,
			limit: pageSize,
			...order,
			...(selectedProcessId && {filter: { where: {otaProcessId: selectedProcessId} }}),
		})
			.then((res) => {
				setLoading(false)

				if (res.data && res.ok) {
					setStatistic(res.data)
				}
			})
			.catch((err) => {
				setLoading(false)
				console.log('err', err)
			})

		return () => setStatistic()
	}, [selectedProcessId, order.sort, order.order, page, pageSize])

	return (
		<Flex gap='middle' vertical style={{ maxWidth: '100%' }}>
			{loading ? (
				<Loader />
			) : (
				<>
					{statistic && (
						<Table
							// onChange={(_a, _b, sorting) =>
							// 	handleOrder({
							// 		order: sorting.order,
							// 		sort: ['otaStatus', 'dispenserStatus'].includes(sorting.columnKey)
							// 			? `${sorting.columnKey}Priority`
							// 			: sorting.columnKey,
							// 	})
							// }
							pagination={{
								position: ['bottomRight'],
								total: statistic?.total,
								pageSize: pageSize,
								current: page + 1,
								onChange: (page, pageSize) => handlePagination(page, pageSize),
							}}
							scroll={{ x: true }}
							columns={columns}
							dataSource={statistic?.rows}
							rowKey={(item) => item.id}
						/>
					)}
				</>
			)}
		</Flex>
	)
})

Statistic.propTypes = {
	selectedProcessId: PropTypes.string.isRequired,
	customers: PropTypes.array.isRequired,
}

export default Statistic
