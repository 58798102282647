import PropTypes from 'prop-types'
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { Button, Divider, Flex, Input, Modal, Steps, List, Typography, Alert } from 'antd'
import { FileUploader } from 'react-drag-drop-files'
import WarningModal from './WarningModal'
import Title from '../Title'
import { CloseCircleOutlined } from '@ant-design/icons'
import { API } from '../../../App'
import { observer } from 'mobx-react'
import { useStore } from '../../../stores'

const VersionNameForm = ({ setStatedValue }) => {
	const [versionName, setVersionName] = useState()
	const [error, setError] = useState()

	useEffect(() => {
		if (versionName?.length === 0) {
			setError({
				status: 'error',
				placeholder: 'Version name field could not be empty',
			})
		} else {
			setError()
		}
	}, [versionName])

	return (
		<Flex gap='middle' style={{ width: '100%', padding: '20px 0' }} vertical>
			<Flex gap='middle' style={{ width: '50%' }} justify={'flex-start'} align='center'>
				<Title asterisk title='Version Name' />
				<Input
					{...error}
					value={versionName}
					onChange={(v) => {
						setVersionName(v.target.value), setStatedValue(v.target.value)
					}}
				/>
			</Flex>

			{error && <Alert style={{ width: '50%' }} message={error.placeholder} type='error' />}
		</Flex>
	)
}

VersionNameForm.propTypes = {
	statedValue: PropTypes.object.isRequired,
	setStatedValue: PropTypes.func.isRequired,
}

const VersionInfoForm = ({ setStatedValue, title, placeholder }) => {
	const [version, setVersion] = useState()
	const [files, setFiles] = useState([])
	const [inputError, setInputError] = useState()
	const [fileError, setFilesError] = useState()

	const [initPhase, setInitPhase] = useState(true)

	useEffect(() => {
		// const isOnlyNumberAndDot = /^\d+(\.\d+)*$/.test(version)

		if (version?.length === 0) {
			setInputError({
				status: 'error',
				placeholder: 'Version could not be empty',
			})
		} else {
			setInputError()
		}
	}, [version])

	useEffect(() => {
		if (files?.length > 0) {
			setInitPhase(false)
		}

		if (files?.length === 0 && !initPhase) {
			setFilesError({
				status: 'error',
				placeholder: 'At least one file should be uploaded',
			})
		} else {
			setFilesError()
		}
	}, [files.length, initPhase])

	const uploadFiles = (filesArr) => setFiles(files.concat(Array.from(filesArr)))

	useEffect(() => setStatedValue({ version, files }), [files.length, version])


	const [firstRender, setFirstRender] = useState(true)

	useEffect(() => {
		if(window.document) {
			const titles = document.querySelectorAll('.sc-fqkvVR');

			if(titles && titles.length > 0) {
				titles?.forEach((title) => {

				if(title && firstRender) {
``
					const text = `Drag and Drop or Browse to Upload Files`;
					const span = title.querySelector('span');

					if(text) {
						span.style.textDecoration = 'unset'
						span.innerText = text.slice(0, 17);


						const textNode2 = document.createElement('span');
						const textNode2_2 = document.createTextNode(text.slice(17, 23));

						textNode2.appendChild(textNode2_2)
						textNode2.style.textDecoration = 'underline'
						span.appendChild(textNode2)

						
						title.lastChild.textContent = `${text.slice(23, text.length)}`

					}
				}
			})
		  }

		}
	}, [])

	return (
		<Flex gap='middle' style={{ width: '100%' }} vertical>
			<Typography.Title level={5}>{title}</Typography.Title>
			<Flex gap='middle' style={{ width: '50%' }} justify={'flex-start'} align='center'>
				<Title asterisk title={'Version'} />
				<Input placeholder={placeholder} {...inputError} value={version} onChange={(v) => setVersion(v.target.value)} />
			</Flex>
			{inputError && <Alert style={{ width: '50%' }} message={inputError.placeholder} type='error' />}

			<Flex gap='middle' style={{ width: '100' }} justify={'space-between'} align='flex-start' flex={'1 1 0'}>
				<FileUploader label={`Drag and Drop or Browse to Upload Files`} classes={'firmware-drop-zone'} multiple handleChange={uploadFiles} name='files' />

				<List
					className='firmware-drop-list'
					header={<div>Uploaded files</div>}
					bordered
					dataSource={files || []}
					renderItem={(item, idx) => (
						<List.Item
							style={{
								overflow: 'hidden',
								textWrap: 'nowrap',
								position: 'relative',
							}}
						>
							<span style={{ width: '90%', textWrap: 'nowrap', overflow: 'hidden' }}>
								{idx + 1}. {item?.name}
							</span>
							<div className='drop-zone_close' style={{ position: 'absolute', right: '5%', top: '25%' }}>
								<CloseCircleOutlined
									onClick={() => setFiles(files.filter((i, index) => index !== idx ))}
								/>
							</div>
						</List.Item>
					)}
				/>
			</Flex>
			{fileError && <Alert style={{ width: '50%' }} message={fileError.placeholder} type='error' />}
		</Flex>
	)
}

VersionInfoForm.propTypes = {
	statedValue: PropTypes.object.isRequired,
	setStatedValue: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
}

const CreateVersionModal = observer(({ isOpen, onCancel }) => {
	const { versionStore } = useStore()

	const [isWarningModalOpen, setIsWarningModalOpen] = useState()

	const [versionName, setVersionName] = useState()
	const [firmwareData, setFirmwareData] = useState()
	const [databaseData, setDatabaseData] = useState()

	const handleWarningModalClose = () => setIsWarningModalOpen(false)

	const [loading, setLoading] = useState(false)

	// Finish step
	const onWarningModalSubmit = async () => {
		console.log({
			versionName,
			firmwareData,
			databaseData,
		})

		setLoading(true)

		try {
			const res = await API.createVersion({
				versionName,
				firmwareData,
				databaseData,
			})

			setLoading(false)

			await versionStore.getLastVersion()

			if (!res.ok) {
				handleWarningModalClose()
				setError({ status: 'error', placeholder: res.data.error.message })
				openNotificationWithIcon('error', res.data.error.message)

				return
			}
		} catch (err) {
			setLoading(false)
			return
		}

		setLoading(false)

		setIsWarningModalOpen(false)

		onCancel()
	}

	const modalStyles = {
		content: {
			width: '80vw',
			maxWidth: '900px',
		},
	}

	const isCreateButtonDisabled = 
		((!firmwareData?.version || firmwareData?.files?.length < 1) && (!databaseData?.version || databaseData?.files?.length < 1 )) || !versionName

	return (
		<Modal title={'Version creation'} open={isOpen} onCancel={onCancel} footer={[]} styles={modalStyles}>
			<Flex gap={'middle'} vertical justify='flex-start' align='flex-start'>
				<VersionNameForm setStatedValue={setVersionName} />

				<Divider style={{ margin: '0px' }} />

				<VersionInfoForm  setStatedValue={setFirmwareData} title={'Firmware'} placeholder='1.1' />

				<Divider style={{ margin: '0px' }} />

				<VersionInfoForm  setStatedValue={setDatabaseData} title={'Database'} placeholder='11.22.33' />

				<WarningModal
					{...{
						cancelButtonText: 'No',
						submitButtonText: 'Yes',
						onSubmit: onWarningModalSubmit,
						title: `Creating a new version will replace the existing one. Do you want to proceed and delete the current version?`,
					}}
					loading={loading}
					isOpen={isWarningModalOpen}
					onCancel={handleWarningModalClose}
				/>

				<Flex gap='middle' style={{ width: '100%', paddingTop: '3rem' }} justify={'flex-end'} align='center'>
					<Button onClick={onCancel} type='primary' danger>
						Cancel
					</Button>
					<Button
						onClick={() => setIsWarningModalOpen(true)}
						disabled={isCreateButtonDisabled}
						type='primary'
					>
						Create
					</Button>
				</Flex>
			</Flex>
		</Modal>
	)
})

CreateVersionModal.propTypes = {
	isOpen: PropTypes.bool,
	onSubmit: PropTypes.func,
	onCancel: PropTypes.func,
}

export default CreateVersionModal
