import React, { useEffect, useMemo, useState } from 'react'
import { Checkbox, Flex, Popover, Typography } from 'antd'
import StatsItem from '../../StatsItem'
import PropTypes from 'prop-types'
import { Loader } from '../../../shared'
import * as moment from 'moment'
import ProcessStatus, { OTAProcessStatus } from '../../ProcessStatus'
import { observer } from 'mobx-react'
import { useStore } from '../../../../stores'

const StatusDetails = observer(({ selectedProcessId }) => {
	const { processDetailsStore } = useStore()

	const details = useMemo(() => processDetailsStore.processDetails, [processDetailsStore.processDetails])

	useEffect(() => {
		const fetchProcessDetails = async (id) => {
			await processDetailsStore.getDetails(id)
		}

		fetchProcessDetails(selectedProcessId)

		return () => processDetailsStore.clearDetails()
	}, [selectedProcessId])

	if (processDetailsStore.inProgress) return <Loader />
	if (!details) return

	const content = details.customers?.map(({ name: customer }) => ` ${customer}`)

	const row = (
		<div
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'space-between',
				alignItems: 'flex-start',
				width: '400px',
				gap: '10px',
			}}
		>
			{details.customers?.map(({ name: customer }, idx) => (
				<Checkbox style={{ width: '45%' }} checked key={customer} disabled>
					{customer}
				</Checkbox>
			))}
		</div>
	)

	const customerPopover = (children) => (
		<Popover style={{ width: '500px' }} content={row} title=''>
			{children}
		</Popover>
	)

	const diff = () => {
		if (details.stoppedAt) {
			const lastTime = moment(details.stoppedAt)

			const hours = lastTime.diff(moment(details.startedAt), 'hours')
			const minutes = lastTime.diff(moment(details.startedAt), 'minutes')

			return `${hours} hours ${minutes - hours * 60} minutes`
		}

		if (!details.endedAt) {
			const now = moment()

			const hours = now.diff(moment(details.startedAt), 'hours')
			const minutes = now.diff(moment(details.startedAt), 'minutes')

			return `${hours} hours ${minutes - hours * 60} minutes`
		}

		const lastTime = moment(details.endedAt)

		const hours = lastTime.diff(moment(details.startedAt), 'hours')
		const minutes = lastTime.diff(moment(details.startedAt), 'minutes')

		return `${hours} hours ${minutes - hours * 60} minutes`
	}

	const endTime = () => {
		let time

		if (
			(details.endedAt || details.stoppedAt) &&
			details.startedAt &&
			[OTAProcessStatus.COMPLETED, OTAProcessStatus.ABORTED].includes(details.status)
		) {
			if (details.endedAt) {
				time = moment(details.endedAt)
			}

			if (details.stoppedAt) {
				time = moment(details.stoppedAt)
			}

			return time.format('MM.DD.YYYY HH:mm')
		}

		return 'Not Finished'
	}

	return (
		<Flex justify='flex-start' align='flex-start' gap={'0.2rem'} style={{ width: '100%' }} vertical>
			<Typography.Title level={4}>{details.processName}</Typography.Title>
			<Flex justify='flex-start' align='flex-start' gap={'2rem'} style={{ width: '100%' }}>
				<Flex
					vertical
					justify='stretch'
					align='flex-start'
					style={{
						flexWrap: 'nowrap',
						columnGap: '1.5rem',
						rowGap: '1rem',
						width: '100%',
					}}
				>
					<StatsItem
						customStyles={{
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							width: '50px',
						}}
						value={content}
						title={'Customers'}
						fullWidth
						popover={customerPopover}
					/>

					<StatsItem
						value={details.firmwareVersion || 'Not Set'}
						color={!details.firmwareVersion && 'rgba(0, 0, 0, 0.25)'}
						title={'Firmware Version'}
						fullWidth
					/>
					<StatsItem
						value={details.databaseVersion || 'Not Set'}
						color={!details.databaseVersion && 'rgba(0, 0, 0, 0.25)'}
						title={'Database Version'}
						fullWidth
					/>
					<StatsItem value={details.status} title={'Status'} fullWidth>
						<ProcessStatus
							status={details.status}
							style={{
								height: '100%',
								width: 'calc(100% + 14px)',
								display: 'flex',
								padding: '0px 14px',
								justifyContent: 'center',
								alignItems: 'center',
								margin: '0 0 0 -7px',
							}}
						/>
					</StatsItem>
				</Flex>
				<Flex
					vertical
					justify='flex-start'
					align='flex-start'
					style={{
						width: '100%',
						flexWrap: 'nowrap',
						columnGap: '1.5rem',
						rowGap: '1rem',
					}}
				>
					<StatsItem
						value={details.startedAt ? `${moment(details.startedAt).format('MM.DD.YYYY HH:mm')}` : 'Not Started'}
						color={!details.startedAt && 'rgba(0, 0, 0, 0.25)'}
						title={'Started On Date'}
						fullWidth
					/>

					<StatsItem
						value={endTime()}
						color={!details.endedAt && !details.stoppedAt && 'rgba(0, 0, 0, 0.25)'}
						title={'Finished On Date'}
						fullWidth
					/>
					<StatsItem value={diff()} title={'Current Duration'} fullWidth />
				</Flex>

				<Flex
					vertical
					justify='flex-start'
					align='flex-start'
					style={{
						width: '100%',
						flexWrap: 'nowrap',
						columnGap: '1.5rem',
						rowGap: '1rem',
					}}
				>
					<StatsItem value={details?.dispensersDetails?.total} title={'Dispensers: Total'} fullWidth />
					<StatsItem value={details?.dispensersDetails?.updated} title={'Dispensers: Updated'} fullWidth />
					<StatsItem value={details?.dispensersDetails?.notUpdated} title={'Dispensers: Not Updated'} fullWidth />
				</Flex>
			</Flex>
		</Flex>
	)
})

StatusDetails.propTypes = {
	selectedProcessId: PropTypes.string.isRequired,
}

export default StatusDetails
