import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Card, message } from "antd"
import { UpdatePasswordForm, ScreenLayout } from "../components"
import { observer } from "mobx-react"
import { API } from "../App"

const UpdatePassword = observer(() => {
	const { token } = useParams()
	const [id, setId] = useState("")
	const navigate = useNavigate()
	useEffect(() => {
		const verifyToken = async () => {
			const { data } = await API.verifyToken({ token })
			if (data.error) {
				message.error(data.error.message)
				return navigate("/login")
			}
			setId(data.id)
		}
		verifyToken()
	})

	return (
		<ScreenLayout>
			<Card title="Reset Password" style={{ maxWidth: 800, margin: "auto", marginTop: 40 }}>
				<UpdatePasswordForm id={id} />
			</Card>
		</ScreenLayout>
	)
})

export default UpdatePassword
