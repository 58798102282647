import React from 'react'
import PropTypes from 'prop-types'
import { Tag } from 'antd'

export const OTAProcessStatus = {
	QUEUED: 'QUEUED',
	READY: 'READY',
	IN_PROGRESS: 'IN_PROGRESS',
	FAILED: 'FAILED',
	COMPLETED: 'COMPLETED',
	ABORTED: 'ABORTED',
}

const OTAProcessStatusLabel = {
	QUEUED: 'None',
	READY: 'None',
	IN_PROGRESS: 'Active',
	FAILED: 'Failed',
	COMPLETED: 'Completed',
	ABORTED: 'Stopped',
}

const OTAProcessStatusColors = {
	QUEUED: 'default',
	READY: 'purple',
	IN_PROGRESS: 'blue',
	FAILED: 'red',
	COMPLETED: 'green',
	ABORTED: 'gold',
}

const ProcessStatus = ({ status, style }) => {
	const upperCaseStatus = status.toUpperCase()
	return (
		<Tag style={style} color={OTAProcessStatusColors[upperCaseStatus]} key={status}>
			{OTAProcessStatusLabel[upperCaseStatus]}
		</Tag>
	)
}

ProcessStatus.propTypes = {
	status: PropTypes.string.isRequired,
	style: PropTypes.object,
}

export default ProcessStatus
