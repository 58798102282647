import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { Modal, message } from "antd"
import { UserEditForm } from "./"
import { API } from "../../../App"

const UserEditModal = ({ user, isModalOpen, handleCancel, onSuccess }) => {
	const formRef = useRef(null)
	const [loading, setLoading] = useState(false)
	const cleanUpAndClose = () => {
		formRef.current.resetFields()
		handleCancel()
	}

	const onFinish = async () => {
		setLoading(true)
		const values = await formRef.current.validateFields()
		const res = await API.updateUserInfo({ roles: [{ id: values.role }], ...values })
		if (res.ok) {
			message.success("User updated")
			onSuccess()
		} else {
			message.error(res.data.error.message)
			return
		}
		setLoading(false)
		cleanUpAndClose()
	}

	return (
		<Modal title="Edit User" onOk={onFinish} open={isModalOpen} onCancel={cleanUpAndClose} confirmLoading={loading}>
			<UserEditForm formRef={formRef} user={user} onFinish={onFinish} />
		</Modal>
	)
}

UserEditModal.propTypes = {
	user: PropTypes.object.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
}

export default UserEditModal
