import { observer } from "mobx-react"
import React from "react"
import { Button, Space } from "antd"
import { LogoutOutlined } from "@ant-design/icons"

export const HeaderButton = observer(({ onClick, text, icon }) => {
	return (
		<div onClick={onClick} style={{ width: "100%" }}>
			{icon}
			{text}
		</div>
	)
})
