import { getDivideResult } from "./calculations"

export const calculateArrayAverage = (array) => {
	if (array.length === 0) {
		return 0
	}

	const sum = array.reduce((total, num) => {
		if (!num || isNaN(+num)) return total + 0
		return total + +num
	}, 0)
	const average = getDivideResult(sum, array.length)
	return average
}

export const calculateWeightArrayAverage = (array, weightTotal) => {
	if (array.length === 0) {
		return 0
	}

	const sum = array.reduce((total, num) => {
		if (!num || isNaN(+num)) return total + 0
		return total + +num
	}, 0)

	const average = getDivideResult(sum, weightTotal)
	return average
}
