import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'antd'
import Loader from '../Loader'

const WarningModal = ({
	loading,
	isOpen,
	onCancel,
	onSubmit,
	submitButtonText,
	title,
	cancelButtonText,
	revertButtons,
}) => {
	const footerButtons = []

	if (onCancel)
		footerButtons.push(
			<Button type={revertButtons && 'primary'} danger={!revertButtons} key='close' onClick={onCancel}>
				{cancelButtonText || 'Close'}
			</Button>,
		)

	if (onSubmit)
		footerButtons.push(
			<Button danger={revertButtons} type={revertButtons ? 'default' : 'primary'} key='submit' onClick={onSubmit}>
				{submitButtonText || 'Save'}
			</Button>,
		)

	if (revertButtons) {
		const dangerButton = footerButtons[0]
		const submitButton = footerButtons[1]

		footerButtons[0] = submitButton
		footerButtons[1] = dangerButton
	}

	return (
		<Modal
			title={!loading ? 'Warning' : 'Loading'}
			open={isOpen}
			onCancel={onCancel}
			footer={!loading ? footerButtons : []}
		>
			{!loading ? title || 'Are you sure?' : <Loader hideSubText />}
		</Modal>
	)
}

WarningModal.propTypes = {
	isOpen: PropTypes.bool,
	title: PropTypes.string,
	onSubmit: PropTypes.func,
	onCancel: PropTypes.func,
	submitButtonText: PropTypes.string,
	cancelButtonText: PropTypes.string,
	revertButtons: PropTypes.bool,
	loading: PropTypes.bool,
}

export default WarningModal
