import React from "react"
import PropTypes from "prop-types"
import { Tag } from "antd"
import { ADMIN } from "../../constants"

const UserRoleTag = ({ role }) => {
	const color = role === ADMIN ? "geekblue" : "green"
	return (
		<Tag color={color} key={role}>
			{role}
		</Tag>
	)
}

UserRoleTag.propTypes = {
	role: PropTypes.string.isRequired,
}

export default UserRoleTag
