import { Button, Card, Form, Input, message } from "antd"
import { observer } from "mobx-react"
import React, { useRef } from "react"
import { ScreenLayout } from "../components"
import { useNavigate } from "react-router-dom"
import { useStore } from "../stores"

const LoginScreen = observer(() => {
	const navigate = useNavigate()
	const { authStore } = useStore()
	const formRef = useRef(null)
	const onFinish = async ({ email, password }) => {
		const response = await authStore.login(email, password)
		if (response.error) {
			const errorMessage = response.error.message || "Something went wrong. Please try again later."
			message.error(errorMessage)
			formRef.current.resetFields()
		} else {
			navigate("/")
		}
	}

	return (
		<ScreenLayout hideNav>
			<Card title="Sign In" bordered={false} style={{ maxWidth: 500, margin: "0 auto", marginTop: 40 }}>
				<Form
					name="login"
					initialValues={{ remember: true }}
					onFinish={onFinish}
					className="login-form"
					layout="vertical"
					ref={formRef}
					style={{ padding: 40 }}
				>
					<Form.Item
						label="Email Address"
						name="email"
						hasFeedback
						rules={[
							{
								type: "email",
								message: "This is not a valid Email Address.",
							},
							{
								required: true,
								message: "A valid email address is required.",
							},
						]}
					>
						<Input autoComplete="username" />
					</Form.Item>
					<Form.Item
						label="Password"
						name="password"
						hasFeedback
						rules={[
							{
								required: true,
								message: "A password is required.",
							},
						]}
					>
						<Input.Password autoComplete="current-password" />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit" className="login-form-button" style={{ width: "100%" }}>
							Sign In
						</Button>
					</Form.Item>
					<Form.Item>
						<Button onClick={() => navigate("/reset-password")} type="default" style={{ width: "100%" }}>
							Reset password
						</Button>
					</Form.Item>
				</Form>
			</Card>
		</ScreenLayout>
	)
})

export default LoginScreen
