import React, { useState, useEffect } from "react"
import { ScreenLayout } from "../components"
import { message } from "antd"
import { useParams, useNavigate } from "react-router-dom"
import { API } from "../App"
import { UserInviteAcceptForm } from "../components"
import { Card } from "antd"

const UserInviteScreen = () => {
	const { token } = useParams()
	const [id, setId] = useState(null)
	const [email, setEmail] = useState(null)
	const navigate = useNavigate()
	useEffect(() => {
		const verifyToken = async () => {
			const { data } = await API.verifyInviteToken({ token })
			if (data.error) {
				message.error(data.error.message)
				navigate("/login")
			}
			setId(data.id)
			setEmail(data.email)
		}
		verifyToken()
	})

	return (
		<>
			{id && email && (
				<Card title="Edit User" style={{ maxWidth: 800, margin: "auto", marginTop: 40 }}>
					<UserInviteAcceptForm email={email} />
				</Card>
			)}
		</>
	)
}

export default UserInviteScreen
