import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Form, Select } from "antd"
import { BaseUserEditForm } from "./"
import { API } from "../../../App"

const UserEditForm = ({ onFinish, formRef, user }) => {
	const [roles, setRoles] = useState([])

	useEffect(() => {
		const fetchRoles = async () => {
			const { data } = await API.getRoles()
			setRoles(data.filter((role) => role.name !== "Customer"))
		}
		fetchRoles()
	}, [])

	return (
		<Form
			name="user-update"
			onFinish={onFinish}
			ref={formRef}
			labelCol={{ span: 6 }}
			wrapperCol={{ span: 16 }}
			initialValues={{ ...user, role: user.roles[0].id }}
		>
			<BaseUserEditForm emailDisabled={true} />
			<Form.Item label="Role" name="role">
				<Select>
					{roles.map((role) => (
						<Select.Option key={role.id} value={role.id}>
							{role.name}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item label="Status" name="status">
				<Select>
					<Select.Option value="Active">Active</Select.Option>
					<Select.Option value="Inactive">Inactive</Select.Option>
				</Select>
			</Form.Item>
		</Form>
	)
}

UserEditForm.propTypes = {
	onFinish: PropTypes.func.isRequired,
	formRef: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
}

export default UserEditForm
